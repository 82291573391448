import React, {useState} from "react"
// import TestimonialCard from './testimonial_card'
import MentorCard from './small_mentor_card'
import { useStaticQuery, graphql } from "gatsby"

const MentorSwitcher = (props) => {
  const mentors = props.mentors
  const [currentMentor, setCurrentMentor] = useState(0)
  const nextMentor = () => {
    setCurrentMentor(currentMentor+1)
  }
  const previousMentor = () => {
    setCurrentMentor(currentMentor-1)
  }
  return(
    <div className="mentor-switch-outer-container">
      <div className="mentor-switch-inner-container" >
        <button 
          className="mentor-left-button" 
          disabled={currentMentor === 0} 
          onClick={previousMentor}
        >
          <img src="https://lbe-assets.sfo2.digitaloceanspaces.com/testimonials/Icon_PageButton.svg" />
        </button>
        <button 
          className="mentor-right-button" 
          disabled={mentors.length === 0 || currentMentor === mentors.length -1} 
          onClick={nextMentor}
        >
          <img src="https://lbe-assets.sfo2.digitaloceanspaces.com/testimonials/Icon_PageButton.svg" />
        </button>
        <div 
          style={{
            transform:`translateX(calc(${currentMentor*-195}% - ${(currentMentor*20)+10}px))`,
            transition: "transform 0.5s",
            willChange: "transform"
          }} 
          className="mentor-switch-container" 
        >
          {mentors.map((m, i)=>{
            return(
              <MentorCard 
                style={{
                  opacity:i===currentMentor?"1":"0.4",
                  transform:i===currentMentor?
                    "scale(1) translateX(0)":
                    i>currentMentor?"scale(0.5) translateX(-40%)":"scale(0.5) translateX(40%)",
                }}  
                key={m.node.id} 
                mentor={m.node}
              />
            )
          })}
        </div>
        <div className="dots-container">
        {mentors.map((m, i)=>{
          return(
            <div 
              style={{backgroundColor:i===currentMentor?"#2fa843":"white"}} 
              key={`${m.node.id}-dot-mobile`}
            />
          )
        })}
        </div>
      </div>
    </div>
  )
}

export default MentorSwitcher