import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import MentorSection from '../components/home/mentor_section'
import TestimonialSwitcher from '../components/home/testimonial_switcher'
import BannerBars from '../components/icons/banner_bars'
import "../components/home/home.css"

const IndexPage = (props) => {
  const {strapiHomepageFrench: data} = useStaticQuery(graphql`
    query {
      strapiHomepageFrench {
        homeText
        meetOurMentorsText
        title
        bannerImage{
          localFile{
            publicURL
          }
        }
        howItWorksFrench{
          stepOneTitle
          stepOneText
          stepOneIcon{
            localFile{
              publicURL
            }
          }
          stepTwoTitle
          stepTwoText
          stepTwoIcon{
            localFile{
              publicURL
            }
          }
          stepThreeTitle
          stepThreeText
          stepThreeIcon{
            localFile{
              publicURL
            }
          }
          stepFourTitle
          stepFourText
          stepFourIcon{
            localFile{
              publicURL
            }
          }
        }
      }
    }
  `)
  
  return (
  <Layout language="french" path={props.path}>
    <SEO title="Home" language="french" />
    <img className="home-mobile-banner" src={data.bannerImage.localFile.publicURL}/>
    <div className="home-top-section">
      <div style={{paddingTop:"20px"}}>
        <h1 className="page-title left">{data.title}</h1>
        <p>{data.homeText}</p>
        <Link
          to="/application"
          style={{padding: "0 30px"}} 
          className="common-button">Demander un mentorat</Link>
      </div>
      <div style={{position:"relative", backgroundImage:`url("${data.bannerImage.localFile.publicURL}")`}}>
        <BannerBars className="banner-bars" />
      </div>
    </div>
    <div className="home-how-it-works">
      <h2 className="section-title">Comment ça fonctionne</h2>
      <div>
        <div className="how-it-works-step">
          <div style={{backgroundColor:"#2fa843"}}>1</div>
          <img src={data.howItWorksFrench.stepOneIcon.localFile.publicURL} />
          <p>{data.howItWorksFrench.stepOneTitle}</p>
          <p>{data.howItWorksFrench.stepOneText}</p>
        </div>
        <div className="how-it-works-step">
          <div style={{backgroundColor:"#dca71c"}}>2</div>
          <img style={{width:"45px"}} src={data.howItWorksFrench.stepTwoIcon.localFile.publicURL} />
          <p>{data.howItWorksFrench.stepTwoTitle}</p>
          <p>{data.howItWorksFrench.stepTwoText}</p>
        </div>
        <div className="how-it-works-step">
          <div style={{backgroundColor:"#2fa843"}}>3</div>
          <img src={data.howItWorksFrench.stepThreeIcon.localFile.publicURL} />
          <p>{data.howItWorksFrench.stepThreeTitle}</p>
          <p>{data.howItWorksFrench.stepThreeText}</p>
        </div>
        <div className="how-it-works-step">
          <div style={{backgroundColor:"#dca71c"}}>4</div>
          <img src={data.howItWorksFrench.stepFourIcon.localFile.publicURL} />
          <p>{data.howItWorksFrench.stepFourTitle}</p>
          <p>{data.howItWorksFrench.stepFourText}</p>
        </div>
      </div>
      
        
      
    </div>
    <div className="meet-our-mentors">
      <h2 className="section-title">Rencontrez Nos Mentors</h2>
      <p>{data.meetOurMentorsText}</p>
      <MentorSection language="french" />
      <Link to="/mentors" style={{margin:"auto"}} className="common-button">Voir tous les mentors</Link>
    </div>
    <div style={{marginBottom:"40px"}}>
      <h2 className="section-title">témoignages</h2>
      <TestimonialSwitcher language="french"/>
    </div>
  </Layout>
)}

export default IndexPage
