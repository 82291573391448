import React from "react"
import BannerBars from '../icons/banner_bars'

const TestimonialCard = (props) => {
  var testimonial = props.testimonial
  var image = testimonial.image && testimonial.image.localFile ? testimonial.image.localFile.publicURL : ""
  return(
    <div style={props.style} className="testimonial-card">
      <BannerBars />
      <div>
        <div/>
        <img alt={testimonial.image.alternativeText || "Image of mentee"} src={image}/>
      </div>
      <p>{props.language==="english"?testimonial.text:testimonial.textFrench}</p>
      <p>
        <span>{testimonial.name}</span> 
          {/* {props.language==="english"?"Age":"Âge"} 
          {testimonial.age} */}
        </p>
      <img alt="" src="https://lbe-assets.sfo2.digitaloceanspaces.com/Icon_QuotationMarks.svg"/>
      <img alt="" src="https://lbe-assets.sfo2.digitaloceanspaces.com/Icon_QuotationMarks.svg"/>
    </div>
  )
}

export default TestimonialCard