import React, {useState} from "react"
import TestimonialCard from './testimonial_card'
import { useStaticQuery, graphql } from "gatsby"

const TestimonialSwitcher = (props) => {
  const {allStrapiTestimonial: data} = useStaticQuery(graphql`
  query {
    allStrapiTestimonial {
      nodes {
        image {
          localFile {
            publicURL
          }
          alternativeText
        }
        id
        age
        name
        text
        textFrench
      }
    }
  }
  `)
  const [currentTestimonial, setCurrentTestimonial] = useState(0)
  const nextTestimonial = () => {
    setCurrentTestimonial(currentTestimonial+1)
  }
  const previousTestimonial = () => {
    setCurrentTestimonial(currentTestimonial-1)
  }
  return(
    <div className="testimonial-switch-outer-container">
      <div className="testimonial-switch-inner-container" >
        <button 
          className="testimonial-left-button" 
          disabled={currentTestimonial === 0} 
          onClick={previousTestimonial}
        >
          <img src="https://lbe-assets.sfo2.digitaloceanspaces.com/testimonials/Icon_PageButton.svg" />
        </button>
        <button 
          className="testimonial-right-button" 
          disabled={data.nodes.length === 0 || currentTestimonial === data.nodes.length -1} 
          onClick={nextTestimonial}
        >
          <img src="https://lbe-assets.sfo2.digitaloceanspaces.com/testimonials/Icon_PageButton.svg" />
        </button>
        <div 
          style={{
            transform:`translateX(calc(${currentTestimonial*-100}% - ${(currentTestimonial*20)+10}px))`,
            transition: "transform 0.5s",
            willChange: "transform"
          }} 
          className="testimonial-switch-container" 
        >
          {data.nodes.map((t, i)=>{
            return(
              <TestimonialCard 
                style={{
                  opacity:i===currentTestimonial?"1":"0.4",
                  transform:i===currentTestimonial?
                    "scale(1) translateX(0)":
                    i>currentTestimonial?"scale(0.5) translateX(-40%)":"scale(0.5) translateX(40%)",
                }} 
                key={t.id} 
                testimonial={t}
                language={props.language}
              />
            )
          })}
        </div>
        <div className="dots-container">
        {data.nodes.map((t, i)=>{
          return(
            <div 
              style={{backgroundColor:i===currentTestimonial?"#2fa843":"white"}} 
              key={`${t.id}-dot`}
            />
          )
        })}
        </div>
      </div>
    </div>
  )
}

export default TestimonialSwitcher