import React from "react"
import BannerBars from '../icons/banner_bars'

const SmallMentorCard = (props) => {
  var {mentor} = props
  if(mentor.node){
    mentor = mentor.node
  }
  var image = mentor.profilePic && mentor.profilePic.localFile ? mentor.profilePic.localFile.publicURL : ""
  return(
    <div className="small-mentor-card">
      <BannerBars />
      <div>
        <div style={{display:image.includes("Placeholder_Mentor_Small")?"none":"block"}}/>
        <img 
          alt={mentor.profilePic&&mentor.profilePic.alternativeText?mentor.profilePic.alternativeText:"Picture Of Mentor"} 
          src={image}
        />
      </div>
      <div>
        <p>{mentor.name}</p>
        <p>{mentor.company}</p>
      </div>
      <div 
        role="text" 
        className="industry-label" 
        style={{
          marginTop:mentor.company?"0":"20px",
          color:mentor.industry.labelTextColor, 
          backgroundColor:mentor.industry.labelColor
        }}>
        {props.language==="english"?mentor.industry.name:mentor.industry.nameFrench}
      </div>
    </div>
  )
}

export default SmallMentorCard