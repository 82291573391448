import React from "react"
import SmallMentorCard from './small_mentor_card'
import MentorSwitcher from './mentor_switcher'
import { useStaticQuery, graphql } from "gatsby";

const MentorSection = (props) => {
  const {allStrapiMentor: data} = useStaticQuery(graphql`
  query {
    allStrapiMentor(limit: 4) {
      edges {
        node {
          id
          name
          company
          bio
          profilePic {
            localFile{
              publicURL
            }
          }
          industry {
            name
            nameFrench
            labelColor
            labelTextColor
            id
          }
        }
      }
    }
  }
  `)
  
  return(
    <div>
    <div className="mentor-section-desktop">
      {data.edges.map((m)=>{
        return(
          <SmallMentorCard language={props.language} mentor={m.node} key={m.node.id}/>
        )
      })}
    </div>
    <div className="mentor-section-mobile">
      <MentorSwitcher language={props.language} mentors={data.edges} />
    </div>
    </div>
  )
}

export default MentorSection
